@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header {@include sec-default(); 
  .mobileCTA{@include sec-default(); display:none; padding: 2px 0; background-color:$color2;text-align: center;
    .insideTw{width:95%;}
    .mcta{display: inline-block;
      &.phone{padding:0 10px;margin:0 10px; border-left:1px solid rgba($white,.5);border-right:1px solid rgba($white,.5);}
    }
    a, button{color:$white; font-weight: 600; text-shadow: 0px 1px 2px rgba($black,.2); font-size: 16px;}
  }
  .navMm{@include sec-default(); padding: 10px 0;} 

  .mobNav{display: none;
    svg{width:30px; height:30px; color:$color1;}
  }
  
  .logoBlk, .navBarCustom, .navItem{display: inline-block;}
  .logoBlk{float:left; padding:0; margin:0; min-height:auto;}
  .navBarCustom{margin-top:35px; float:right; text-align: center;
    a:hover, li a:hover {opacity: 1; color:$color2;}
    li.reqLi:hover, li.reqLi a:hover{color:$white; opacity: .9;}
  }

  .ilLogo{width:330px; @include center-block();}
  .navItem {margin:0; padding: 0 12px; font-weight: 600; color: $color1;}
  .custDrop{position:absolute; z-index: 99; opacity: 0;visibility: hidden; background-color: darken($fa, 1.5%); box-shadow: 0 5px 12px rgba($black, 0.15);
    padding:5px 0 0px; margin-top:0; text-align: left;
    ul{padding:0;}
    li{width:100%; float:left; margin: 10px 0; padding:0;
      a{padding: 0px 0 0px 10px; color: $color1; font-size: .95rem;}
    }
    &.contactDrop{max-width:300px;}
  }
  .navItem.withDrop:hover .custDrop, .custDrop:hover{opacity: 1;visibility: visible; transition: .4s;}
  
  .phoneLi{padding-left:20px; border-left: 1px solid rgba($color1, .4); margin-left:10px;}
  .phoneLi, .emailLi{font-weight:500; font-size: 16px;}
  .reqLi{background-color:$color2; padding: 6px 15px; margin-left: 15px; font-size:15px; font-weight:bold; box-shadow:0px 5px 20px rgba($color1,.3);
    button, a{color:$white;}
  }
  .clearfix{display: none;}

  // &.reg{position: relative; z-index: 0; background-color: $color2-ltr;}
  &.reg{background-color: $color2-ltr;}

  @media screen and (min-width:1201px){
    &.homeHead{
      .navMm{padding: 10px 0 0;} 
      .insideXl{width:90%;}
      .ilLogo{float:left;}
      .navBarCustom{width:62%; text-align:left; margin:30px auto 0px auto;}
      .reqLi{position: absolute; right:5%; margin-top:-5px; padding: 10px 30px; font-size:17px; font-weight:bold;}
    }
    &.reg .insideXl{width:1195px;}
  }

  @include bp(tw){
    .insideXl{width:95%;}
    .logoBlk {@include center-block(); width:330px;}
    .ilLogo{width:auto;}
    .navBarCustom{width:100%; float: left; margin: 0;}
    .navBarCustom{margin:15px 0 5px;}
  }

  @include bp(tb){
    .navBarCustom{display:none;}
    .mobNav{display:block;position:absolute; right:2%; top:35px;}

    .mobileCTA{display:block;}
  }

  @include bp(oc){
    .mobNav{right:1%; top:30px;}
    .mobileCTA{
      .mcta.phone{padding:0 7px;margin:0 7px;}
      a{font-size: 15px;}
    }
  }

  @include bp(sm){
    .logoBlk{float:left; width:auto;}
    .mobNav{right:0; top:25px;}
    .ilLogo{width:300px; float:left;}
  }

  @media screen and (max-width:374px) {
    .mobNav{top:15px;}
    .ilLogo{width:240px;}
  }
}

.SideNavList{padding:40px 10px 0 10px; width:350px;
  .SideNav{margin-top:10%;}
  .closeIcon {color: $color1; position: absolute; right:20px; top:15px; cursor: pointer; font-weight: normal;
    svg{width:30px; height:30px;}
  }

  .navItem {@include sec-default(); margin:0; padding: 15px 15px;
    a{font-weight: 600; color: $color1; text-transform: none;font-size:1.1rem;}
    &.phoneLi a, &.emailLi a{color:$color2;}
    .gcmLink{width:100%; font-size:1rem; padding:10px 10px 10px 20px; background-color:$color2; text-align: left;}
  }
}

