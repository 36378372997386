@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.CertificationSlide {
  .textCenter{@include sec-default(); padding: 50px 0;
    p{font-weight: 500; font-size: 1.25rem; margin: 0 0;
      &.lead{font-size: 1.6rem; font-weight: bold;}
    }
  }

  .styleIL{@include sec-default(); display: none; text-align: center; padding-bottom: 40px;
    .certImg{height:85px; @include center-block();}
    .inner{width:100px; height:100px; border-radius: 50%; background-color: $white; box-shadow: 0px 5px 10px rgba($color1,.2); padding-top:7px; overflow: hidden;}
    .crmi .inner{background-color:#d1d49d;}
    .slideLogo{display: inline-block; width:100px; margin:0 3%;}
  }

  .styleOG2{@include sec-default(); margin-top:30px;padding: 50px 0 30px; position: relative;
    z-index: 0; &::before{@include before(); @include sec-bgimage('./images/bgGreen.png'); width:40%; left:30%; top:5%; top:1%;}
    
    .logoRowLeft,.logoRowRight,.midRow{display: inline-block; position: relative;vertical-align: top;}
    .logoRowLeft,.logoRowRight{width:25%;}
    .logoRowLeft{float: left;}
    .logoRowRight{float: right;}
    
    .midRow{text-align: center;width:40%; margin-left:5%; 
      p{color:$white; font-size: 1.6rem; font-weight: bold;text-shadow: 0px 1px 2px rgba($black,.2);margin-top:16%;}
    }

    .certImg{height:100px; @include center-block();}
    .inner{width:120px; height:120px; border-radius: 50%; background-color: $white; box-shadow: 0px 5px 10px rgba($black,.2); padding-top:10px; overflow: hidden;
      -webkit-animation-name:hvrBob-float,hvrBob; animation-name:hvrBob-float,hvrBob;
      -webkit-animation-duration:2s,3s; animation-duration:2s,3s;
      -webkit-animation-timing-function:ease-out,ease-in-out; animation-timing-function:ease-out,ease-in-out;
      -webkit-animation-iteration-count:1,infinite; animation-iteration-count:1,infinite;
      -webkit-animation-fill-mode:forwards; animation-fill-mode:forwards;
      -webkit-animation-direction:normal,alternate; animation-direction:normal,alternate;
    }
    .slideLogo{
      &.ac2{margin-left:40%;}
      &.aq {margin-top:27%;}
      &.mstr{float:right; margin-right:40%;}
      &.infr{margin-top:27%;float:right;}
      &.crmi .inner{background-color:#d1d49d;}
    }

    .logoRowBtm{@include sec-default(); text-align: center; margin-top:30px;
      .slideLogo{position: relative; display: inline-block; margin: 0 10%;
        &.ac2, &.aq, &.mstr, &.infr {display: none;}
      }
    }

    @media screen and (min-width:1800px){
      &::before{@include sec-bgimage('./images/bgGreenShort.png'); width:40%; left:30%; top:1%;}
      .insideAlt {width:1400px;}
    }

    @media screen and (min-width:1024px){
      .slideLogo {
        -webkit-transform:translateZ(0); transform:translateZ(0);
        -webkit-backface-visibility:hidden; backface-visibility:hidden;
        -moz-osx-font-smoothing:grayscale;
        -webkit-animation-iteration-count:1,infinite; animation-iteration-count:1,infinite;
        -webkit-animation-fill-mode:forwards; animation-fill-mode:forwards;
        -webkit-animation-direction:normal,alternate; animation-direction:normal,alternate;

        &.slideOff{
          -webkit-animation-name:slideOff-float,slideOff; animation-name:slideOff-float,slideOff;
          -webkit-animation-duration:5s,5s; animation-duration:5s,5s;
          -webkit-animation-timing-function:ease-out,ease-in-out; animation-timing-function:ease-out,ease-in-out;
          .inner{-webkit-animation-duration:4s,4s; animation-duration:4s,4s;}
        }
      }
    }

    @include bp(hs) {
      &::before{top:4%;}
      .logoRowBtm .slideLogo{margin: 0 8%;}
    }

    // @include bp(tw) {
    @media (max-width: 1300px) {
      .insideAlt{width:85%;}
      &::before{width:46%; left:27%; top:8%;}
      .midRow{width:50%; margin-left:0;
        p{font-size: 1.5rem; margin-top:18%;}
      }
    }

    @include bp(tb) {margin-top:15px;
      .insideAlt{width:95%;}
      &::before{width:54%; left:23%; top:5%;}
      .midRow{
        p{font-size: 1.45rem; margin-top:15%;}
      }
      .slideLogo{
        &.ac2{margin-left:25%;}
        &.aq {margin-top:27%;}
        &.mstr{float:right; margin-right:25%;}
        &.infr{margin-top:27%;float:right;}
      }

      .logoRowBtm {margin-top:15px;
        .slideLogo{margin: 0 7%;}
      }
    }

    @include bp(tn) {
      &::before{width:60%; left:20%; top:2%;}
      .logoRowLeft{width:20%;}
      .logoRowRight{width:20%;}
      
      .midRow{width:60%;
        p{font-size: 1.3rem; margin-top:10%;}
      }

      .certImg{height:85px;}
      .inner{width:100px; height:100px; padding-top:7px;}
      .slideLogo{
        &.ac2{margin-left:0;}
        &.aq {margin-top:27%;}
        &.mstr{margin-right:0;}
        &.infr{margin-top:27%;}
      }
    }

    @include bp(md) {padding-bottom:15px;
      .midRow p{margin-top:8%;}
      .logoRowBtm {margin-top:0;}
    }

    @include bp(mb) {
      &::before{top:5%;}
      .midRow p{font-size: 1.2rem; margin-top:8%;}
    }

    @include bp(oc) {text-align: center;
      &::before{width:80%; left:10%; top:-20px;}
      .logoRowLeft, .logoRowRight{display:none;}
      .midRow{@include sec-default(); p{font-size: 1.2rem; margin-top:0px;}}
      .logoRowBtm .slideLogo {
        &, &.ac2, &.aq, &.mstr, &.infr {display:inline-block; float:none; margin: 10px 3%;}
      }
    }

    @include bp(ph) {
      &::before{width:95%; left:2.5%; top:-20px;}
      .logoRowBtm .slideLogo {
        &, &.ac2, &.aq, &.mstr, &.infr {margin: 10px 1.5%;}
      }
    }
  }

  &.HomePage, &.faq{
    .styleOG2{display: none;}
    .styleIL{@include sec-default();}
  }
  
  &.HomePage .styleIL{
    .slideLogo{margin:0px 1.5%;}

    @include bp(nn) {padding-bottom:30px;.slideLogo{margin:0px 4%;}}
    @include bp(oc) {.slideLogo{margin:0px 2%;}}
  }

  &.faq .styleIL{text-align: left;
    .slideLogo{margin:8px 2%;}
  }

  @media screen and (min-width:1024px){
      // HVR Slide ?? //
    @-webkit-keyframes slideOff {
      0%{-webkit-transform:translateX(-5%);transform:translateX(-5%);}
      50%{-webkit-transform:translateX(5%);transform:translateX(5%);}
      100%{-webkit-transform:translateX(10%);transform:translateX(10%);}
    }
    @keyframes slideOff {
      0%{-webkit-transform:translateX(-5%);transform:translateX(-5%);}
      50%{-webkit-transform:translateX(5%);transform:translateX(5%);}
      100%{-webkit-transform:translateX(10%);transform:translateX(10%);}
    }
    @-webkit-keyframes slideOff-float{100%{-webkit-transform:translateX(10%);transform:translateX(10%);}}
    @keyframes slideOff-float{100%{-webkit-transform:translateX(10%);transform:translateX(10%);}}

    // HVR BOB //
    @-webkit-keyframes hvrBob{
      0%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}
      50%{-webkit-transform:translateY(-4px);transform:translateY(-4px);}
      100%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}
    }
    @keyframes hvrBob{
      0%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}
      50%{-webkit-transform:translateY(-4px);transform:translateY(-4px);}
      100%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}
    }
    @-webkit-keyframes hvrBob-float{100%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}}
    @keyframes hvrBob-float{100%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}}
  }
}