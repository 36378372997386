@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
//&family=Asap:wght@700&display=swap');
@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

::selection{background: lighten($color2, 15%);}
::-moz-selection {background: lighten($color2, 15%);}
*, *::before, *::after {box-sizing: border-box;}
body{margin:0;}
html {line-height: 1.5em; font-size: 17px; color:$color1;font-family:$body; background-color: $ice;
  h1, h2, h3, h4, h5, p, li, a, span {color: $color1; line-height: 1.4em; margin: 8px 0; font-family:$body;
    &.bmargin0 {margin-bottom: 0;}
    &.bmargin5 {margin-bottom: 5px;}
  }
  p,li{font-weight: 400;}
  p{font-size: 1.1rem; margin-top:7px;}
  span{color:inherit;}
  
  h1{font-size: 1.7rem; line-height: 1.3em; font-weight:bold;}
  h2{font-size:1.4rem;line-height: 1.3em;}
  h3{font-size: 1.2rem;}
  h4{font-size:1.1rem;}

  @include bp(tb) {
    h1{font-size: 1.9rem;}
    h2{font-size:1.3rem;}
    h3{font-size: 1.2rem;}
    h4{font-size:1rem;}
  }
}

a, button {text-decoration: none;
  &:hover {opacity: .8; text-decoration: none; @include ease(.3s);}
}
button{background: none; border:none; border-radius:0; font-size:inherit; color:inherit; font-weight:inherit; letter-spacing: inherit; font-family: inherit;
  &:hover{cursor: pointer;}
}
img{@include center-block();}
ul {margin:0; list-style-type: none; padding-left: 0;}
li{list-style-type: none;}

.gcmdWrap{@include sec-default(); 
	.Content{@include sec-default(); min-height:65vh;}
}

.ToTopBtn{margin-right:-15px; margin-bottom: 20px;}




// HVR BOB //
// @-webkit-keyframes hvrBob{
//   0%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}
//   50%{-webkit-transform:translateY(-4px);transform:translateY(-4px);}
//   100%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}
// }
// @keyframes hvrBob{
//   0%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}
//   50%{-webkit-transform:translateY(-4px);transform:translateY(-4px);}
//   100%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}
// }
// @-webkit-keyframes hvrBob-float{100%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}}
// @keyframes hvrBob-float{100%{-webkit-transform:translateY(-8px);transform:translateY(-8px);}}   
// .hvrBob{-webkit-transform:translateZ(0);transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden;-moz-osx-font-smoothing:grayscale;}
// .hvrBob:active,.hvrBob:focus,.hvrBob:hover{-webkit-animation-name:hvrBob-float,hvrBob;animation-name:hvrBob-float,hvrBob;-webkit-animation-duration:.3s,1.5s;animation-duration:.3s,1.5s;-webkit-animation-delay:0s,.3s;animation-delay:0s,.3s;-webkit-animation-timing-function:ease-out,ease-in-out;animation-timing-function:ease-out,ease-in-out;-webkit-animation-iteration-count:1,infinite;animation-iteration-count:1,infinite;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-direction:normal,alternate;animation-direction:normal,alternate;}