@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.requestModal {
  .reqModal{width:1000px; padding:45px 0 20px;}
  .modalBod{width:90%; @include center-block();}
  .modalHd{@include sec-default(); text-align: center; margin-bottom: 30px; padding-bottom:10px; border-bottom:1px solid rgba($black,.1);}
  p.modalLead{font-size:1.4rem; font-weight: 500;}
  svg{color:$black; width:20px;}

  @include bp(tb) {
    .reqModal{width:95%;}
    p.modalLead{font-size:1.2rem;}
  }
  // @include bp(br) {
  //   .reqModal{width:95%;}
  // }
}