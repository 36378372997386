@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Contact {@include sec-default();
  .ContactHd{
    h1 span.emph::before{margin-top:-30px; width:115%; left:-7.5%}
    h2{font-size: 2rem;}

    span.emph::before{width:115%; left:-5%; margin-top: -28%;
      background-image: url('../../assets/images/scribble.png');
    }
  }

  // .cInfo{@include sec-default(); padding:60px 0 60px; text-align: center;
  //   .ciBox{display: inline-block; margin:0 4%; vertical-align: top;text-align: left;}
  //   p.info{margin-bottom: 25px; font-size:1.2rem; border-bottom:2px solid rgba($color1,.7); padding-bottom: 8px;
  //     b{font-weight: 500;}
  //   }
  //   p.cat{font-size:.95rem; letter-spacing: 5px; text-transform: uppercase; font-weight: bold; margin-bottom: 15px;}
  // }

  .cInfo{@include sec-default(); padding:60px 0 60px; text-align: center;
    .ciBox{display: inline-block; vertical-align: top; text-align: left;margin:0 3%;
      &.box4{border-bottom:2px solid rgba($color1,.7);padding-bottom: 8px; float:right;}
    }

    p.info{margin-bottom: 25px; font-size:1.2rem; border-bottom:2px solid rgba($color1,.7); padding-bottom: 8px;
      b{font-weight: 500;}
    }
    p.cat{font-size:.95rem; letter-spacing: 5px; text-transform: uppercase; font-weight: bold; margin-bottom: 15px;}
    a.fb{background-color:$color1; color:$white;}
  }

  .cForm{@include sec-default(); padding: 80px 0; margin-bottom: 80px; position: relative; z-index: 0; 
    &::after{@include before(); left:0; top:0; width:65%; z-index: -2;
      @include sec-bgimage('./images/rolledTallRev.jpg');background-size:50%; background-repeat: repeat; 
    }
    &::before{@include before(); width:65%; box-shadow: 0px 5px 35px rgba($color1,.2); background-color:rgba($white,.94);}

    .cfInner, .cfImage{display:inline-block;}
    .cfInner{width:45%; margin-left:5%; float:left;}
    .cfImage{width:40%; float:right; margin-right:5%;
      .imgFix{display: none;}
    }

    .cFormWrap,.customer,.property,label, .cfield {@include sec-default();}

    .formGroup{display: inline-block; margin-bottom: 15px; width:32%; 
      &.fgemail, &.fgaddress{margin-left:2%; margin-right:2%;}
      &.fgaddress{width:48%;}
      &.fgsize{width:16%}
      &.fgdets,&.btn{@include sec-default();}
    }
    
    label{font-size:.95rem;}
    input, textarea, select {border:none; border-bottom:2px solid rgba($color1,.3); font-family: $mont; padding:2px 2px 2px 5px; font-weight: 500; margin-bottom:20px;}
    input, select {min-height:35px;}
    textarea {min-height:125px; margin-bottom:00px;}
    .gcmLink{font-size:15px; min-height: auto; padding: 7px;width:135px;}

    h4, .h4Label{font-weight: 600; margin:0 0 7px; font-size: 1rem;}
    .titleLine{width:70px; height:3px; background-color:$color2; margin-bottom: 20px;}
  }


  @include bp(tw){
    .ContactHd{padding:60px 0 90px;}

    .cInfo{
      .medDef{width:900px;}
      .ciBox{
        &.box1{margin-left: 0; float:left;}
        &.box4{margin-right:0;}
      }
      // .ciBox{margin:0 2%;}
      p.info{font-size:1.1rem;}
      p.cat{font-size:.92rem; letter-spacing: 3px;}
    }

    .cForm{padding: 60px 0 30px; margin-bottom: 40px;
      .insideTw{width:90%;}
      .cfInner{width:45%;margin-left:3%;}
      .cfImage{width:48%;margin-right:-2%;
        .imgFix{display: block;}
        .imgRel{display: none;}
      }
  
      .formGroup{width:100%; 
        &.fgemail, &.fgaddress{margin-left:0; margin-right:0;}
        &.fgaddress,&.fgsize{width:100%;}
      }
    
      h4, .h4Label{margin-bottom: 10px;}
      input, textarea, select {margin-bottom:5px;}
    }
  }
  
  @include bp(tb){
    .ContactHd{
      h1{
        br{display: none;}
      }
    }

    .cInfo{ padding:30px 0;
      // .ciBox{margin:0 4%;}
      p.info{font-size:1rem;}
      p.cat{font-size:.9rem; margin-bottom: 10px; letter-spacing: 3px;}
    }

    .cForm{padding:40px 0 30px;
      .cfInner{width:50%;}
      .cfImage{width:45%;}
    }
  }

  @include bp(br) {
    .cInfo{padding:15px 0 20px;
      .medDef{width:600px;}
      .ciBox{
        &.box1, &.box2, &.box4 {margin:0 4%; float:none;}
        &.box3{width:58%; @include center-block();}
      }

      p.info{margin-bottom:15px;}
      p.cat{font-size:.88rem; margin-bottom: 5px; letter-spacing: 1px;}
    }
  }
 
  @include bp(nn){.ContactHd h1 br{display: block;}}

  @include bp(tn){
    .ContactHd{padding:15px 0 30px;
      h1{
        span.emph::before{margin-top:-20px; width:110%; left:-5%}
        br{display: none;}
      }
      h2{font-size: 1.35rem;}
      
    }

    .cInfo{padding:15px 0 0px;}

    .cForm{padding: 20px 0 10px; margin-bottom: 0px;
      // &::before{display: none;}
      &::before{width:100%; box-shadow: none; background-color:rgba($ice,.9);}
      &::after{width:100%;}// @include sec-bgimage('./images/rolledTallRev.jpg'); background-size:50%; background-repeat: repeat;}
      
      .cfInner{width:90%; @include center-block();}
      .cfImage{display:none;}
      .cFormWrap{background-color:$white; padding:15px 25px;box-shadow: 0px 10px 24px rgba($color1,.1);}
    }
  }

  @include bp(md){
    .ContactHd{
      h2{font-size: 1.2rem;}
    }
  }

  @include bp(oc){
    .ContactHd{padding:5px 0 20px;
      h1 br, h2 br{display: none;}
    }

    .cInfo{padding:15px 0 0;
      .medDef{width:90%;}
      .ciBox{
        &.box1, &.box2, &.box3, &.box4{width:80%; @include center-block();}
      }
    }

    .cForm{padding:20px 0 10px;
      .insideTw{float:right;}
      .cfInner{@include sec-default();}
    }
  }
}