@import './variables.scss';
@import './mixins.scss';

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .insideXl, .insideN, .insideNrw, .insideTw{ @include center-block(); max-width:1700px;}
.insideN{width:95%;}
.inside{width: 85%;}
.insideXl {width: 90%;}
.insideAlt {width: 80%;}
.medDef {width:1080px;}


@media (max-width:1235px) {
  .medDef {width:90%;}
}

// @include bp(xl) {
  .med2Def{width: 940px;}
  .med3Def{width: 850px;}
// }

@include bp(br) {
  .med2Def, .med3Def {width:85%;}
}

@media screen and (min-width:823px){
  .insideNrw{width:820px;}
}

@include bp(tn) {
  .insideNrw{width:90%}
}

.fullRow{@include sec-default();}
.centerBlock{ @include center-block();}
.bmargin0 {margin-bottom: 0;}
.tmargin0 {margin-top: 0;}
.textCenter {text-align: center;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1 {@include sec-default();height:10px;}
.spacer2 {@include sec-default();height:20px;}
.spacer25 {@include sec-default();height:25px;}
.spacer3 {@include sec-default();height:30px;}

br.tw, br.tb, br.br, br.tn, br.ph, br.sm, br.xs, br.oc{display: none;}
@include bp(tw) {br.tw{display:block;}}
@include bp(tb) {br.tb{display:block;}}
@include bp(br) {br.br{display:block;}}
@include bp(tn) {br.tn{display:block;}}
@include bp(oc) {br.oc{display:block;}}
@include bp(ph) {br.ph{display:block;}}
@include bp(sm) {br.sm{display:block;}}
@include bp(xs) {br.xs{display:block;}}

hr{border-color: rgba($black,.1);
	&.hlf {width: 50%;}
	&.less {width: 25%;}
}
.clearfix::after, .clearfixBtn::after {
  content: "";
  clear: both;
  display: table;
}
.clearfix.btn, .clearfixBtn {@include sec-default(); margin-top:20px;}
.clearBorder, .clearBorderWhite, .clearBorderLeft{@include sec-default(); height:50px; position: relative; z-index: 0; margin:0px 0 20px;
  &::before{@include before(); width:1px; left:49.7%; background-color: rgba($black,.2);}
}
.clearBorderWhite::before{background-color:rgba($white, .3);}
.clearBorderLeft::before{left:12%;}

.gcmLink,.gcmLinkRound, .gcmLinkAlt {@include link($color1, $white); padding: 10px 20px;  
  @include bp(tb) {font-size:15px;}
  @include bp(br) {font-size:14px;}
}
.gcmLinkRound{border-radius: 40px; width:200px;}
.gcmLinkAlt{background-color: $color2;}


.textLink{font-weight: bold;
  &.und {text-decoration: underline;}
}

.simpleHeader{@include sec-default(); text-align: center; padding: 10px 0 35px;}

.visuallyHidden {@include visuallyHidden();}
.imgResponsive {max-width: 100%; height: auto; display:block;}
ul.disc li{list-style-type: disc;}


// EMPH TEXT
span.emph, span.emphSmall, span.emphLong, span.emphCustom{position: relative;
  &::before{@include before();width:104%; height:230%; margin-top:-12%; left:-2%; @include sec-bgimage('./images/scribble0.png'); background-position: center center;}
}
span.emphLong::before{margin-top: -9%;}
span.emphSmall::before{margin-top: -15%;}

.PageHeader{@include sec-default(); text-align: center; background-color: $color2-ltr; padding:80px 0 125px;
  border-bottom-right-radius: 25%;
  h1,h2{position: relative; z-index:0;font-weight: 500;}
  h1{font-size: 2.2rem; padding-bottom: 15px; line-height: 1.6em;}
  a{font-weight: 600;}
  br.cust,br.cust2{display: none;}

  @include bp(tw){
    padding:60px 0 90px;
  }
  @include bp(tb) {padding:40px 0 60px;
    h1{font-size: 1.8rem;}
    h2{font-size: 1.6rem;}
  }

  @include bp(og) {
    h1, h2{
      span.emph, span.emphSmall, span.emphLong, span.emphWhite, span.emphCustom{
        &::before{display:none;}
      }
    }
  }

  @include bp(tn){padding:20px 0;
    h1{font-size: 1.4rem; padding-bottom: 0;} 
  }

  @include bp(md){
    h1{font-size: 1.3rem;}
    h2{font-size: 1.2rem;}
  }

  @include bp(oc){padding:10px 0 12px;
    .med2Def{width:90%;}
    h1{font-size: 1.2rem;}
    h2{font-size: 1.1rem;}
  }

}