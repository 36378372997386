@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Accordion {
  @include sec-default();
  li {@include sec-default(); margin: 0 0 15px; text-align:left;}
  li:nth-child(n+2){margin-top:inherit;}
  button {background-image:none; background:transparent; border:none; font-family: $body; font-weight: bold; font-size: 1.15rem;}

  .custContent{padding-left: 20px;}
  p{margin-top:5px;}
  
  &.openLgAcc {
    @media (min-width:1024px) {
      li{position: relative;z-index: 0;
        &::before {@include before(); z-index: 1;}
      }
      li button {padding-left:0;
        &::before{display: none;}
      }
      [hidden] {display: block !important;}
      .custContent{margin-top:0; padding-left:0;}
    }
  }

  // KPKP
  &.sizeColor{
    li{margin-bottom:40px;
      margin:20px 0;box-shadow: 0px 5px 20px rgba($color1,.1);
      padding:30px;background-color: $white;}//box-shadow: 0px 5px 20px rgba($black,.2);}
    .custContent{width:98%;}
    .custTitle {@include sec-default(); text-align: left;margin-bottom:15px; color:$color2;}
    p{margin-bottom: 15px;}
    
    @include bp(br) {
      li{margin-bottom:0px; padding:0px; box-shadow: 0px 5px 20px rgba($black,0); background-color: transparent;}
      .custTitle {margin-bottom: 0;}
      .custContent{padding-top:15px; padding-left:10px;}
    }
    @include bp(oc) {
      .custTitle,p {font-size:1.05rem;}
    }
  }
}