@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

// box-shadow: 0px 10px 30px rgba($black, .3);
// box-shadow: 2px 0px 20px rgba($black,.3);

.HomeFull {@include sec-default();
  .introSec{@include sec-default(); padding:60px 0; margin-bottom:30px;
    position: relative; z-index:0; &::after{@include after(); width:80%; right:0; height:38%; bottom:-6%; background-color:$color2-ltr;}
    .left, .right{display: inline-block;}
    .left{width:40%; float:left; margin-top:5%;}
    .right{width:57%; float:right;}
    img.mobile,.innerRight{display: none;}

    h1{font-size:1.75rem; font-weight: 400;
      span{font-weight: 500; color:$color2;}
      b{font-weight: 500; color:$color2;}
      br{display: none;}
    }
    h2{font-size:1.7rem;margin:45px 0 10px; color:darken($color2-md,5%); font-style:italic; font-weight: 500;}
    h3{font-size:1.6rem; font-weight: 400;
      b{font-weight: 500;}
      span{color:$color2-md;}
    }
    a{font-size:1.3rem; font-weight: 600; color:$color2;}

    @media screen and (max-width:1445px){
      .left{margin-left: -1.5%; margin-top:3%;}
    }

    @media screen and (max-width:1405px){
      .left{width:45%; margin-top:1.5%;}
      .right{margin-right: -2%;}
      h3 br{display: none;}
    }

    @include bp(tw){padding:50px 0 40px;
      .left{width:47%; margin-left:-3%; margin-top:3%;}
      .right{width:57%; margin-right: -2%;}
      h1{font-size:1.5rem;}
      h2{font-size:1.4rem; margin-top:30px;}
      h3{font-size: 1.35rem;}
      a{font-size:1.2rem;}
    }

    @include bp(tb){padding:50px 0 30px;margin-bottom: 15px;
      .inside{width:90%;}
      .left{width:47%; margin-left:-1%; margin-top:7.5%; padding-right: 10px;}
      .right{width:54%; margin-right:-1%;}
      img.fw{display: none;}
      img.mobile{display: block;}

      h1{font-size:1.4rem;}
      h2{font-size:1.3rem; margin:30px 0 0;}
      h3{font-size: 1.25rem;}
      a{font-size:1.15rem;}
    }

    @include bp(br){
      .left{width:50%; margin-left:0;}
      .right{width:50%; margin-right:-1%;}

      h1{font-size:1.3rem;}
      h2{font-size:1.2rem; margin:30px 0 0;}
      h3{font-size: 1.15rem;
        br{display: block;}
      }
      a{font-size:1.05rem;}
    }

    @include bp(tn){padding:15px 0;margin-bottom: 0px;
      &::after{height:90%; bottom:-5%;}
      .left{@include sec-default(); margin:0 0 30px; text-align: center;}
      .right{@include sec-default(); margin-right:0;}
      img.mobile{display: none;}
      .innerRight{@include sec-default(); height:250px; @include sec-bgimage('./images/modern-wall-damageTn.jpg');}
      h2{margin:15px 0 0;}
      h3 br{display: none;}
    }

    @include bp(pl){
      .inside{width:95%;}
    }

    @include bp(oc){padding:10px 0;
      .innerRight{height:200px;}
      h2{margin-top: 10px;}
    }

    @include bp(xs){padding:10px 0;
      .left{margin-bottom: 20px;}
      .innerRight{height:200px;}
      h1{font-size:1.2rem;}
      h2{font-size:1.1rem; }
      h3{font-size: 1.05rem;}
    }
  }

  .hs2{@include sec-default(); text-align: center; padding:80px 0 60px; 
    .logo{width:500px; @include center-block(); margin-bottom:30px;}
    .btnRow{@include sec-default(); margin:15px 0;}
    .clearfix{display:none;}
    p{font-size: 1.4rem; margin-bottom:18px;}
    p.lead{font-size: 1.5rem;}
    p.cat{font-size: 1.2rem; font-weight: bold; text-transform: uppercase; letter-spacing: 4px; margin-bottom: 10px; color:$color2-md;}

    .gcmLink{margin: 0 2%; box-shadow:0px 5px 20px rgba($color1,.35); padding:10px 30px;}
    button.gcmLink{padding:11.5px 30px; background-color: $color2;} 

    @include bp(tb){padding:60px 0 60px;
      p{font-size: 1.25rem; margin-bottom:18px;}
      .gcmLink{width:200px;margin: 0 2%;}
    }
    @include bp(br){padding:40px 0;
      p{font-size: 1.15rem; margin-bottom:18px;}
    }

    @include bp(tn) {padding:40px 0 25px}

    @include bp(og) {
      p.cat{font-size: 1.05rem;letter-spacing:1px;}
      p{font-size: 1.1rem;}
      .gcmLink{width:auto;margin: 0 2% 15px;}
    }

    @include bp(oc) {padding:20px 0 15px;
      p.cat{font-size: 1rem;letter-spacing:.5px;}
      p{font-size: 1.05rem;}
      .clearfix{display:block; margin-bottom:25px;}
    }
  }

  .hsCert{@include sec-default(); padding-bottom:25px;
    @include bp(tb) {padding-bottom: 15px;}
    @include bp(br) {padding-bottom: 0px;}
  }

  .hsWhy{@include sec-default(); padding:80px 0 10px; margin-bottom: 80px;
    position: relative; z-index: 0; &::before{@include before(); background-color: $color2-ltr; height:25%; width: 80%;}
    .medDef{width:70%;}

    .row{@include sec-default();}
    .btnRow{@include sec-default(); margin-bottom: 40px;}
    .image, .text{display: inline-block; position: relative;}
    .image{width:57%; z-index:0; float:left}
    .text{width:40%; float:right; z-index:1; margin-right:-1%; margin-bottom: 350px;}
    p.btmp{margin:15px 0 0;}
    p.cat{font-size: 1.2rem;font-weight: bold; color:darken($color2-md, 5%); margin:11% 0 8px;}
    .textLink{float:right; color: $color2; margin-right: -5%; text-shadow: 1px 1px rgba($color2-dk,.1); font-size:1.05rem;
      svg{width:25px; height:25px;}
    }
  
    .imageAbs{position: absolute;bottom: 0;right:0; max-height:350px; overflow: hidden; // width:63%;//before plax
      width:55%;
      .inner{@include sec-bgimage('./images/corner-wall-mold.jpg'); position: relative; height:350px;}
    }

    .textB{width:70%; margin-left:2.5%;margin-top:10%; // OG
      p,a{font-size: 1.3rem; margin-bottom:20px;}
      p.cat{font-size: 1.5rem;margin:0 0 15px;}
      a{font-weight: 600; color: $color2; }
      .gcmLink{width:240px; padding:15px 20px; box-shadow:0px 5px 20px rgba($color1,.4);}
    }

    @media screen and (max-width:1530px) {
      .medDef{width:80%;}
      .textB br{display: none;}
    }

    @media screen and (max-width:1400px) {margin-bottom: 60px;
      .medDef{width:85%;}
      .textB{width:90%;margin-top:7%;}
      .text{margin-bottom: 300px;}
      .imageAbs{width:70%; max-height:300px;}// width:95%}
      .inner{height:300px;}
    }

    @include bp(tw){.imageAbs{width:95%;}}
    @include bp(tb){margin-bottom:10px; padding:45px 0 5px;
      &::before{height:35%;}
      .image{width:54%; margin-left:-3%;}
      .text{width:47%; margin-right:-2%; margin-bottom: 0px;}
      p.cat{font-size: 1.12rem;}
      p.cat.first{margin-top:7%;}
      .textB{width:95%; margin-left:2%; margin-top:8%;
        p,a{font-size: 1.17rem; margin-bottom:20px;}
        p.cat{font-size: 1.35rem;margin:0 0 5px;}
        .gcmLink{width:200px;}
      }
      .imageAbs{left:0;width:51%; max-height:200px;}
      .inner{height:200px;}
    }

    @include bp(br){margin-bottom:0px; padding:30px 0 0;
      &::before{height:45%;}
      .medDef{width:80%;max-width:800px;}
      .image, .text{@include sec-default(); margin:0 0 20px;}
      p.cat{margin:20px 0 5px;font-size: 1.1rem;}
      p.cat.first{margin-top:0px;}
      .btnRow{margin-bottom: 20px;}
      .textLink{font-size:1rem;}
      .img1{width:500px;}
      .textB{@include sec-default(); margin:40px 0 20px;
        p.cat{font-size: 1.2rem;}
        p,a{margin-bottom:10px; font-size: 1.1rem;}
        p.lastp{margin-bottom:18px;}
      }
      
      .imageAbs{position: relative; width:100%;}
      .inner{height:200px;}
    }

    @include bp(tn){
      .medDef{max-width:650px;}
      p.cat{font-size: 1.05rem;}
      .textLink{margin-right:0;}
      .textB{margin:30px 0 20px;
        p.cat{font-size: 1.1rem;}
        p,a{font-size: 1.05rem;}
        p.lastp{margin-bottom:18px;}
      }
    }

    @include bp(oc){
      &::before{height:32%;}
      .medDef{width:90%;max-width:auto;}
      .img1{width:90%;}
    }
  }  
}