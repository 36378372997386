$fa: #fafaf8;
$fa2: darken($fa, 1%);
$df: #dfdfdf;
$sand: #FCFBFA; // OG
// $sand: #fbfcff;
// $sand: #f3f7ff;
// $ice:#fbfdfe;
$ice: #fcfefe;
$white: #fff;
$black: #000;
$red: #bb2830;

$color1: #102030;
$color2: #5eb445;
$color1-lt:#f5f5f6;
$color2-lt:#eef7ec;
$color2-ltr:#F3F9F1;
$color2-dk:  #1c3614;
$color2-md: darken($color2, 8%);
$color1-md:#0890d0;
$color1R:#234669;
$color1R2:#084868; //#060E3E
// $color1R3:#0068a0;



$border: 1px solid rgba($black,.1);

$mont: 'Montserrat', sans-serif;
$ethno: 'Ethnocentric';
$micro: 'MicrogrammaD-BoldExte';
$body: $mont;

$semi: 500;
$light: 300;