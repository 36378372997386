@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.FAQ{@include sec-default();padding-bottom: 80px;
  span.emphCustom::before{width:115%; left:-5%; margin-top: -28%;
    background-image: url('../../assets/images/scribble.png');
  }
  span.emphLong::before{margin-top: -13%;}



  .colorFaq{@include sec-default();position: relative; z-index: 0;
    &::before{@include before();@include sec-bgimage('./images/texture07.png'); background-repeat: repeat;background-size:65%; opacity: .1;}
    &::after{@include before(); background-color:rgba($ice,.75);}
  }

  .questionBlk, .qList {display: inline-block; vertical-align: top;}
  .questionBlk {width:35%;margin-left:-2%;
    li{@include sec-default(); margin-bottom:15px; 
      a{color:$color1; font-weight: 600; font-size: 1.05rem;
        // position: relative; z-index: 0;&::before{@include after(); height: 8px; bottom:2px; left:-1px; width:100px; background-color: rgba($color2, .25);}
        b{color:$color2;}
      }
    }
  }
  .qList {width:64%; float:right;}
  ul{margin-top:40px;}
  
  @include bp(tw) {
    .questionBlk {margin-left:1%;}
    .qList{margin-right: -3%;}
  }

  @include bp(tb) {
    h1 br{display: none;}
    .PageHeader.FaqHd{padding:40px 0;}

    .colorFaq{padding:30px 0;}
    .questionBlk {width:35%;
      li{margin-bottom:15px; 
        a{
          &::before{height: 5px; bottom:1px;width:85px;}
        }
      }
    }
    .qList {width:61%; margin-right: -2%;}
    ul{margin-top:20px;}
  }
  
  @include bp(br) {padding-bottom:60px;
    .colorFaq{padding:0px 0 0; margin-left:0;}
    .questionBlk{display: none;}
    .qList {width:80%; @include center-block();}
  }
  
  @include bp(tn) {
    .qList li .text{@include sec-default();}
  }
  
  @include bp(md) {
    .qList {width:90%;}
  }
  
  @include bp(oc) {
    .PageHeader.FaqHd{padding:20px 0;
      h1 br{display:none;}
    }
    // .colorFaq .medDef{width:95%;}
    .qList {width:100%;}
  }
}