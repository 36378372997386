@font-face {
  font-family: "Ethnocentric";
  src: url("./assets/fonts/ETHNOCEN.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/ETHNOCEN.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/ETHNOCEN.otf") format("opentype"), /* Open Type Font */
    url("./assets/fonts/ETHNOCEN.svg") format("svg"), /* Legacy iOS */
    url("./assets/fonts/ETHNOCEN.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./assets/fonts/ETHNOCEN.woff") format("woff"), /* Modern Browsers */
    url("./assets/fonts/ETHNOCEN.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MicrogrammaD-BoldExte";
  src: url("./assets/fonts/MICROGBE.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/MICROGBE.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/MICROGBE.otf") format("opentype"), /* Open Type Font */
    url("./assets/fonts/MICROGBE.svg") format("svg"), /* Legacy iOS */
    url("./assets/fonts/MICROGBE.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./assets/fonts/MICROGBE.woff") format("woff"), /* Modern Browsers */
    url("./assets/fonts/MICROGBE.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}