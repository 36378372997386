@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.About{@include sec-default();
  .as2Inline{@include sec-default(); padding:80px 0;
    .left, .right{display: inline-block; vertical-align: top;}
    .left{width:45%; margin-left:-2; margin-top: 4%;}
    .right{float: right; width:51%; margin-right: -2%;}

    h2{font-size:1.5rem;}
    h3{font-size:1.3rem;}
    a{font-size:1.15rem; color:$color2; font-weight: bold; margin: 10px 0; display: inline-block;}
    a:first-child{margin-right:5px;}
    p{margin-bottom: 15px;}

    @include bp(tb){padding:60px 0;
      .left{margin-left: 2%;}
      .right{margin-right: 2%; width:50%;}
      h2{font-size:1.47rem;}
      h3{font-size:1.27rem;}
      a{font-size: 1.12rem;}
    }

    @include bp(br){padding:30px 0 15px; text-align: center;
      .left,.right{@include sec-default(); margin:5px 0;}
      h2{font-size:1.35rem; margin: 0 0 5px;
        br{display: none;}
      }
      h3{font-size:1.2rem; margin:0;}
      a{margin:5px 0;}
    }

    @include bp(tn) {padding:30px 0 10px;
      a{margin-bottom: 0;}
    }

    @include bp(sm){
      .med2Def{width:90%;}
      h2{font-size:1.3rem;}
      h3{font-size:1.2rem;}
    }

    @include bp(xs){
      .med2Def{width:95%;}
      h2{font-size:1.2rem;}
      h3{font-size:1.1rem;}
    }
    @include bp(xx){
      .med2Def{width:95%;}
      h2{font-size:1.1rem;}
      h3{font-size:1rem;}
      a{font-size: .95rem;}
    }
  }

  .asGridStep{@include sec-default(); padding:0px 0 60px; position: relative; z-index: 0;
    &::before{@include before(); height:49%; top:14%; background-color: $color2-ltr;}
    .insideTw{width:90%; width:1200px; position: relative;}
    .ptitle{@include sec-default();margin-bottom:20px; @include sec-bgimage('./images/petri-mold.jpg'); position: relative; height:350px;
      p{font-size:1.8rem; font-weight: bold; color:$white; text-shadow: 0px 1px 2px rgba($color1,.9);
        margin-top:12%; text-align: center;
      }
      // .plax{@include sec-default(); @include sec-bgimage('./images/petri-mold.jpg'); position: relative; height:450px;}
      // .text{display:none; position:absolute; z-index: 1; background-color: rgba($color1,.8); bottom:0;width:100%;text-align:center;}
    }

    .mdStep{display: inline-block; margin: 30px 0 40px; position: relative; z-index: 0; padding-left:10px;
      &::before{@include before();font-weight:bold; font-size:80px; color: rgba($color2,.2); top:20%; left:-2%;}
      &.one{width:55%;
        &::before{content:"1";}
      }
      &.two{width:40%; float:right;
        &::before{content:"2";}
      }
      &.three{width:30%;
        &::before{content:"3";}
      }
      &.four{width:65%; float:right;
        &::before{content:"4";}
      }
      &.five{width:50%;float:right;display:block; // width:100%; 
        &::before{content:"5";}
      }
      &.six{width:50%;float:right;display:block;// width:100%; 
        &::before{content:"6";}
      }
      &.svn{width:35%;
        &::before{content:"7";}
      }
      &.eight{width:60%; float: right;
        &::before{content:"8";}
      }

      p span {text-decoration: underline;}
      p b{font-weight: 600;}
      b.green{color:$color2;}
    }
    .imgRow{@include sec-default(); position: relative;
      .imgAbs{width:65%; position: absolute; left:-20%;
        // height:100%;img{display: none;}
        // background-image: url('../../assets/images/adobe-new5.jpg'), url('../../assets/images/adobe-ir.jpg');
        // background-size: 49%, 49%; background-position: top left, top right; background-repeat: no-repeat, no-repeat;
      }
      .imgAbs.mobile{display: none;}
      // .steps{width:50%; float: right;}
    }

    @include bp(tw) {padding-bottom: 40px;
      .insideTw{width:90%;}
      .ptitle{height:400px;}

      .mdStep{margin:20px 0 20px;
        &.one, &.three{margin-left:2%;}
      }
      .imgRow {margin: 10px 0 30px;
        .imgAbs{width:61%; left:-14%;}
      }
    }

    @include bp(tb) {padding-bottom: 20px;
      &::before{top:18%;}
      .ptitle {height:300px; margin-bottom: 15px;
        p{font-size:1.5rem;}
      }

      .imgRow {margin: 0px 0 30px;
        .imgAbs.fw{display: none;}
        .imgAbs.mobile{display: block; width:54%; left:-7%;}
      }
    }

    @include bp(nn) {
      &::before{top:16%;}
      .mdStep{margin:10px 0;}
      .imgRow{margin:10px 0 30px;}
    }

    @include bp(tn) {
      &::before{top:14%; height:54%;}
      .ptitle {height:250px;
        p{font-size:1.3rem;}
      }

      .mdStep{
        &.five{width:52%; margin-top:30px;}
        &.six{width:85%; @include center-block(); margin-top:30px;}
      }
      .imgRow{margin:10px 0 5px;
        .imgAbs.mobile{width:44%; left:0; position: relative; float: left;}
      }
    }

    @include bp(md) {
      &::before{top:14%; height:51%;}
      .mdStep.five{width:52%; margin-top:15px;}
    }

    @include bp(og) {
      .medDef{width:80%;}
      .mdStep{
        &.one, &.two, &.three, &.four, &.five, &.six, &.svn, &.eight{@include sec-default();margin:5px 0 7px;}
      }
      .imgRow .imgAbs.mobile{display: none;}
    }
    
    @include bp(oc) {
      &::before{top:5%; height:60%;}
      .ptitle {height:200px;
        p{font-size:1.1rem; br{display: block;}}
      }
      .medDef{width:90%;}
    }

    @include bp(ph) {
      &::before{top:3%;}
      .mdStep::before{left:-1%;}
      .ptitle {height:150px;
        p{margin-top:8%;}
      }
    }

    @include bp(sm) {
      &::before{top:1%;}
      .ptitle {height:100px;
        // p{margin-top:6%;}
      }
    }
  }

  .qual{@include sec-default(); background-color: $color2-ltr; padding: 50px 0; text-align: center;
    p{font-size: 1.2rem; margin: 0;
      &.lead{font-size: 1.6rem; font-weight: bold;}
    }
    @include bp(br){padding: 30px 0;
      .insideNrw{width:600px;}
      p{font-size:1.1rem;
        &.lead{font-size: 1.4rem;}
      }
    }

    @include bp(oc){padding: 20px 0;
      .insideNrw{width:90%;}
      p{font-size:1rem;
        &.lead{font-size: 1.2rem;}
      }
    }
  }

  @media screen and (max-width:884px){.AboutHd br.cust{display:block;}}
  @include bp(tn){.AboutHd br.cust{display:none;}}
  @include bp(og){.AboutHd br{display:none;}}
  // @include bp(oc){.AboutHd .med2Def{width:95%;}}
}