@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.cFormWrap {@include sec-default();
  p.successSub{color:$color2;}
  p.errorSub{color:$red;}

  .customer,.property,label, .cfield {@include sec-default();}

  .formGroup{display: inline-block; margin-bottom: 15px; width:32%; 
    &.fgemail, &.fgaddress{margin-left:2%; margin-right:2%;}
    &.fgaddress{width:48%;}
    &.fgsize{width:16%}
    &.fgdets,&.btn{@include sec-default();}
  }
  
  label{font-size:.95rem;}
  input, textarea, select {border:none; border-bottom:2px solid rgba($color1,.3); font-family: $mont; padding:2px 2px 2px 5px; font-weight: 500; margin-bottom:20px;}
  input, select {min-height:35px;}
  textarea {min-height:125px; margin-bottom:00px;}
  .gcmLink{font-size:15px; min-height: auto; padding: 7px;width:135px;}

  h4, .h4Label{font-weight: 600; margin:0 0 7px; font-size: 1rem;}
  .titleLine{width:70px; height:3px; background-color:$color2; margin-bottom: 20px;}

  @include bp(br) {
    .formGroup{@include sec-default(); margin:0 0 15px;
      &.fgemail, &.fgaddress,&.fgaddress,&.fgsize{@include sec-default(); margin:0 0 15px;}
    } 
  }

}