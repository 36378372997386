@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Health {@include sec-default();
  .types, .intro {@include sec-default(); padding:80px 0;}
  .HealthHd{
    h1 span.emph::before{margin-top:-30px; width:115%; left:-7.5%}
    h1 span.emphLong{padding-bottom: 8px;}
    h2{font-size: 1.6rem; line-height: 1.6em;}

    span.emphCustom::before{width:115%; left:-5%; margin-top: -28%;
      background-image: url('../../assets/images/scribble.png');
    }
  }
  
  .intro {padding:80px 0 80px; position: relative; z-index: 0;
    &::before{@include before(); width:32%;@include sec-bgimage('./images/rolledTall.jpg');background-size: cover; background-position: top right;opacity: .45;}
    &::after{@include before(); background-color:rgba($ice,.8);}

    .insideTw{margin-left:37%; width:52%;margin-left:33%; width:48%;}
    p{font-size:1.3rem; margin-bottom: 15px;}
    p.mbLg{margin-bottom: 50px;}
    
    p.cat{font-size: 1.05rem; font-weight: bold; text-transform: uppercase; letter-spacing: 4px; margin-bottom: 10px;
      color: darken($color2, 22%); //color:$color1R2;color:$color2-md;
    }
    b{color:$color2-md; }//color:$color1R2;}
    b.b1{font-weight: 500;} // 500 ** IF BOLD COLOR IS GREEN, THINNER WEIGHT IS BETTER. IF DARK, HEAVIER IS BETTER. **
  }

  .cSympt {@include sec-default(); padding:40px 0 80px;
    .symptoms, .image{display: inline-block;}
    .image{width: 67%; float:right; margin-right: -5%;}
    .symptoms {width:33%; float:left; margin-left: -5%; margin-top:2.5%;}

    .info{@include sec-default(); margin-top: 80px;
      p{font-size:1.3rem;line-height: 1.7em;}
    }

    p.csTitle{font-size: 1.2rem; font-weight: bold; margin-bottom: 20px;}
    ul{float:right;}
    li{font-size: 1.15rem; margin:0 0 15px;position: relative; z-index: 0;
      &::before{@include before();left:-22px;width:12px; height:12px; top:7.5px;@include sec-bgimage('./images/specsGrn.png');}
    }
  }

  .lcSymptB {@include sec-default(); padding:80px 0;margin-bottom: 30px;
    position: relative; z-index: 0; &::before{@include after(); width:46%; left:0; background-color: $color2-ltr;}
    .left,.right,.info{display: inline-block; vertical-align: top;}
    .info{width: 40%; float:left; margin-top:13%; 
      p{font-size: 1.5rem; font-weight: bold;}
      p br{display: none;}
    }
    .left {width:30%; margin-left: 11%;}
    .right{width:14%; margin-right:-1%; float:right;}

    ul{padding:35px 0 10px;}
    li{font-size: 1.15rem; margin-bottom: 15px;position: relative; z-index: 0;
      &::before{@include before(); width:12px; height:12px; top:7.5px;left:-22px;@include sec-bgimage('./images/specsGrn.png');}
    }
  }

  .types {padding:80px 0 40px;
    position: relative; z-index: 0;
    &::before{@include after(); //width:40%; 
      right:0; bottom:0; height:85%; @include sec-bgimage('./images/rolledTallRev.jpg');
      // background-size: cover; 
      background-position: bottom right;opacity: .45;
      background-size:40%;background-repeat: repeat;
    }
    &::after{@include before(); background-color:rgba($ice,.88);}


    .top{@include sec-default(); text-align: center; margin-bottom: 40px;}
    .typeNames{@include sec-default();}
    
    h3{font-size: 1.6rem; font-weight: bold; color: darken($color2, 22%);}

    .oneType{display: inline-block; width:48%; margin-bottom: 30px;
      p{font-size: 1.03rem; line-height: 1.6em;}
      p.tTitle{font-weight: bold; color: $color2; font-size: 1.1rem;}
    }
    .oneType {float:left;
      &.two, &.four, &.six{ float:right; clear:after;}
      &.one{width:60%;}
      &.two{width:36%;}
      &.three{width:36%;}
      &.four{width:60%;}
      &.five{width:60%;}
      &.six{width:36%;}
    }
    
    // .oneType{
    //   &:nth-child(2n+2){float:right; clear:both; margin-right: -2%;}
    // }

  }


  @include bp(tw) {
    .intro {padding:40px 0;
      .insideTw{margin-left:27%; width:62%;}
    }

    .cSympt {
      .image{margin-right: -2%;}
      .symptoms {margin-left: -2%;}
    }
    .types {padding:50px 0 40px;}
  }

  @include bp(tb) {
    .HealthHd{
      // h1 span.emph::before{margin-top:-30px; width:115%; left:-7.5%}
      // h1 span.emphLong{padding-bottom: 8px;}
      h2{font-size: 1.4rem; line-height: 1.6em;}
    }

    .intro {
      .insideTw{margin-left:25%; width:64%;}
      p{font-size:1.2rem;}
      p.cat{font-size: .95rem;}
      p.mbLg{margin-bottom: 30px;}
    }

    .cSympt {
      .image{width: 62%; margin-right: 0;}
      .symptoms {width:31%; margin-left: 2%; margin-top:2%;}
  
      .info{margin-top: 50px;
        p{font-size:1.15rem; width: 95%; @include center-block();}
      }
  
      p.csTitle{font-size: 1.2rem;}
      li{font-size: 1.1rem; margin:0 0 12px;
        &::before{left:-18px;width:10px; height:10px; top:7px;}
      }
    }

    .lcSymptB {padding:15px 0; margin-bottom: 30px;
      &::before{width:42%;}
      .info{width: 40%; margin-top:13%; 
        p{font-size: 1.35rem;}
      }
      .left {width:30%; margin-left: 6%;}
      .right{width:14%; margin-right:4%;}
  
      ul{padding:35px 0 10px;}
      li{font-size: 1.1rem; margin-bottom: 12px;
        &::before{left:-18px; width:10px; height:10px; top:7px;}
      }
    }
    
    .types {padding:15px 0;
      h3{font-size: 1.4rem;}
      p {font-size:1.05rem;}
    }
  }

  @include bp(tn) {
    .HealthHd{
      h2{font-size: 1.2rem;}
    }

    .intro {padding:30px 0;
      &::before{width:32%;}
      .insideTw{margin-left:5%; width:90%;}
      p{font-size:1.05rem;}
      p.mbLg{margin-bottom: 25px;}
    }

    .cSympt {padding:20px 0;
      .image{width: 52%; margin-right: 0;}
      .symptoms {width:42%; margin-left: 0; margin-top:0;}
  
      .info{margin-top: 25px;
        p{font-size:1.05rem; width: 100%;}
      }
  
      p.csTitle{font-size: 1.1rem; margin-bottom: 10px;}
      li{font-size: 1.05rem; margin:0 0 10px;
        &::before{left:-18px;width:10px; height:10px; top:7px;}
      }
    }

    .lcSymptB {padding:20px 0; margin-bottom: 0px;
      .medDef{width:90%;}
      &::before{width:50%;}

      .info,.left,.right{@include sec-default(); text-align: center; padding: 0; margin:0;
        p{font-size: 1.05rem;}
      
        li{font-size: 1.05rem; margin-bottom: 10px;
          &::before{left: auto; margin-left:-18px; width:10px; height:10px; top:7px;}
          br{display: none;}
        }
      }
    }

    .types {padding:15px 0;
      .top{margin-bottom: 15px;}
      h3{font-size: 1.2rem;}
      p,.oneType p{font-size:1rem;}
      .oneType{margin-bottom: 10px;
        &.one, &.two, &.three, &.four, &.five, &.six{@include sec-default();}
      }
    }
  }

  @include bp(pl) {
    .cSympt {
      .image{width: 46%;position: absolute; right:5%;}
      .symptoms {width:95%;}
      ul{float:left;}
  
      .info{margin-top: 10px;}
    }
  }

  @include bp(oc) {
    .HealthHd{
      h1 br, h2 br{display: none;}
    }
    .cSympt {padding:10px 0 40px;
      .image{position: relative; @include center-block(); width:80%;}
      .symptoms {@include sec-default(); margin-top:20px; text-align: center;}
      ul{float:none;}
      li::before{left:auto; margin-left: -15px;}
  
      .info{margin-top: 0px;}
    }

    .lcSymptB{
      .info,.left,.right{
        p br, li br{display: block;}
      }
    }
  }
}