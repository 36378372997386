@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer {@include sec-default(); padding:50px 0 0; background-color: $color2-ltr; border-top-left-radius: 35%;
  .med2Def{width:1100px;}
  .TopFt{margin-bottom: 15px;}
  .ftColumn{display: inline-block; vertical-align: top;
    p{font-size:.98rem;}
    b{font-weight: 600;}

    &.logo{width:26%; margin-top:20px; float: left; margin-left: 4%;
      img{max-width: 280px;}
    }
    &.contact{width:24%; float: right; margin-right:-5%;}
    &.blurb{width:38%; margin-left:7%;
      .logoSm{max-width:280px;display:none;}
    }
    p.cat{font-size: 1rem; font-weight: bold; text-transform: uppercase; letter-spacing: 4px; margin-bottom:0px;}
    .titleLine{width:70%; height:2px; background-color:$color1; margin-bottom:5px;}
    .btnRow{@include sec-default(); margin-top:7px;}
    .gcmLink{font-size:.83rem; background-color: $color2; box-shadow:0px 4px 20px rgba($color1,.3);}
    a.fb{width:25px; height:25px; color:$white; background-color:$color1; }

  }

  .mobFtNav{display:none; background-color: $color1; 
    a, span, button, svg{color:$white;}
    button.custTitle{padding:0; font-size:1rem; font-weight: bold; letter-spacing: 1px; text-transform: uppercase;}
    .accordList{width:200px; @include center-block();}
    .custContent{margin-top:7px;}
    .navItem{margin-bottom: 12px;}
    .phoneLi, .emailLi, .mnBtn{display:none;}
  }

  .copyHs{@include sec-default(); text-align: center; margin-top:15px;
    p{display: inline-block; font-size: 14px; margin:10px 0 5px;}
    br{display: none;}
  }

  @include bp(tw) {
    .med2Def{width:940px;}

    .ftColumn{
      &.logo{width:28%; margin-top:20px; margin-left: -1%;
        img{max-width: 100%;}
      }
      &.contact{width:26%; margin-right:-5%;}
      &.blurb{width:40%; margin-left:8%;}
    }
  }

  @include bp(tb) {
    .med2Def{width:940px;}
    .TopFt{margin-bottom: 5px;}
    .ftColumn{
      &.logo{width:27%; margin-top:20px; margin-left: 1%;
      }
      &.contact{width:26%; margin-right:0;
      }
      &.blurb{width:40%; margin-left:4%;
      }
    }
  }

  @include bp(br) {padding:30px 0 0;
    .TopFt{margin-bottom: 0px;}
    .med2Def{width:700px;}
    .ftColumn{text-align: center;
      &.logo{display:none;}
      &.contact,&.blurb{@include sec-default(); margin: 0 0 6px 0;
        .logoSm{@include center-block();}
      }
      .titleLine{width:230px; @include center-block();}
      .btnRow{margin-bottom: 30px;}
    }
    .mobFtNav{@include sec-default();}
  }

  @include bp(md) {
    .med2Def{width:90%;}
  }

  @include bp(pl) {.copyHs br{display:block;}}
  @include bp(oc) {border-top-left-radius: 25%;}
  @include bp(sm) {.copyHs br{display:none;}}
}